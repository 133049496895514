import classnames from 'classnames'
import { PropsWithChildren } from 'react'
import Slider from 'react-slick'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import classes from './FeaturedPosts.module.scss'
import { ThePost } from './ThePost'
import { Post } from './index'

export type MobileSliderProps = {
  posts: Post[]
  numberOfPosts: number
}

export const MobileSlider: React.FC<PropsWithChildren<MobileSliderProps>> = ({
  posts,
}) => {
  const isSmall = useMediaQuery('(max-width: 768px)')

  return (
    <div>
      <Slider
        accessibility
        dots={true}
        centerMode
        centerPadding={isSmall ? '10%' : '20%'}
        className={classnames(classes.posts_slider, classes[`posts-${posts.length}`])}>
        {posts.map((Post: Post, i: number) => {
          return (
            <div key={i} className={classes['featured-posts__slider-slide']}>
              <ThePost
                post={Post}
                keyProp={i}
                numberOfPosts={posts.length}
              />
            </div>
          )})}
      </Slider>
    </div>

  )
}
