import classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import React, { PropsWithChildren, useState } from 'react'
import { Grid } from '../../components/Grid'
import { DownloadIcon } from '../../components/icons/DownloadIcon'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import classes from './FeaturedPosts.module.scss'
import { Post } from './index'

export type ThePostProps = {
  post: Post
  classname?: string
  keyProp: number
  numberOfPosts: number
}

export type CursorTypes = {
  isActive?: boolean
  mouseX: number
  mouseY: number
  cursorColor?: string
}

export const Cursor: React.FC<PropsWithChildren<CursorTypes>> = ({
  mouseX,
  mouseY,
  isActive,
  cursorColor,
}) => {
  return (
    <motion.div
      className={classes.cursorWrapper}
      initial={{
        scale: 0,
        opacity: 0,
      }}
      animate={{
        x: mouseX,
        y: mouseY,
        scale: isActive ? 1 : 0,
        opacity: isActive ? 1 : 0,
      }}
      transition={{
        duration: .25,
        ease: 'easeOut',
      }}
      exit={{ opacity: 0 }}
    >
      <motion.b
        className={classes.cursor}
        style={{
          background: cursorColor,
        }}
      >Read More</motion.b>
    </motion.div>
  )
}

export const ThePost: React.FC<PropsWithChildren<ThePostProps>> = ({
  post,
  classname,
  numberOfPosts,
  keyProp,
}) => {
  const mobile = useMediaQuery('(max-width: 1023px)')
  const [mouseActive, setMouseActive] = useState(false)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const optimisedPostImage = `${post.image?.filename}${IMAGE_FILTER}`
  const imageClasses = []

  const onMouseMove = (e: React.MouseEvent, isActive: boolean) => {
    const { left, right, bottom, top } = e.currentTarget.getBoundingClientRect()

    if (e.clientX < left) {
      setMouseActive(false)

      return
    }

    if (e.clientX > right) {
      setMouseActive(false)

      return
    }

    if (e.clientY < top) {
      setMouseActive(false)

      return
    }

    if (e.clientY > bottom) {
      setMouseActive(false)

      return
    }

    const newX = e.clientX - left - 40
    const newY = e.clientY - top - 40

    setMouseActive(isActive)
    setMousePosition( { x: newX, y: newY })
  }

  switch (post.image_type) {
  case 'square':
    imageClasses.push(classes.squareImg)
    break

  case 'rectangle':
    imageClasses.push(classes.squareImg)
    break

  case 'verticalRectangle':
    imageClasses.push(classes.verticalRectangle)
    break

  case 'smallerSquare':
    imageClasses.push(classes.smallerSquare)
    break

  default:
    imageClasses.push(classes.squareImg)
    break
  }

  const variants = {
    closed: {
      y: 50,
      opacity: 0,
      display: 'none',
    },
    open: {
      y: 0,
      opacity: 1,
    },
  }

  return (
    <div className={classes['post-container']}>
      {keyProp == numberOfPosts - 1 && numberOfPosts % 2 ?
        <Grid className={classnames(classname, classes['featured-posts__post'], classes['featured-posts__post--placeholder'])}>
          <div />
        </Grid>
        : null}
      <Grid className={classnames(classname, classes['featured-posts__post'])}>
        {post?.image?.filename &&
          <React.Fragment key={post.id}>
            <motion.div
              className={classnames(classes['featured-posts__motion'])}
              variants={variants}
            >
              <a href={post?.post_link?.cached_url} target={post?.post_link?.linktype === 'url' ? '_blank' : '_self'} rel="noreferrer" aria-label={post.post_title}>
                <div className={classnames(classes[`featured-posts__post-image-${post.image_type}`])}>

                  {!mobile ? (
                    <div
                      onMouseMove={(e) => onMouseMove(e, true)}
                      onMouseEnter={(e) => onMouseMove(e, true)}
                      onMouseLeave={(e) => onMouseMove(e, false)}
                      className={classnames(imageClasses)}
                    >
                      <Image
                        src={(post.image?.filename) ? optimisedPostImage : 'https://a.storyblok.com/f/105336/279x160/8b837292bf/logo.png'}
                        layout="fill"
                        objectFit="cover"
                        alt={post.image.alt}
                      />
                      <AnimatePresence>
                        <Cursor mouseX={mousePosition.x} mouseY={mousePosition.y} isActive={mouseActive} cursorColor={post?.cursor_color?.color} />
                      </AnimatePresence>
                    </div>
                  ) : (
                    <div>
                      <Image
                        src={(post.image?.filename) ? optimisedPostImage : 'https://a.storyblok.com/f/105336/279x160/8b837292bf/logo.png'}
                        width={1024}
                        height={700}
                        alt={post.image.alt}
                      />
                    </div>
                  )}
                </div>
              </a>
            </motion.div>
            <motion.div
              variants={variants}
              className={classnames(classes['post-description-wrapper'])}
            >
              <div className={classnames(classes['featured-posts__h4-container'])}>
                {post.is_downloadable ? (
                  <>
                    <a href={post?.post_link?.cached_url} target={post?.post_link?.linktype === 'url' ? '_blank' : '_self'} rel="noreferrer"> <h4>{post.post_category}</h4> </a>
                    <a href={post?.post_link?.cached_url} target={post?.post_link?.linktype === 'url' ? '_blank' : '_self'} rel="noreferrer">
                      <a href={post.post_link.cached_url} target={post.post_link.linktype === 'url' ? '_blank' : '_self'}  rel="noreferrer">
                        <DownloadIcon className={classnames(classes['featured-posts__download'])} />
                      </a>
                    </a>
                  </>
                ) : (
                  <a href={post?.post_link?.cached_url} target={post?.post_link?.linktype === 'url' ? '_blank' : '_self'} rel="noreferrer"><h4>{post.post_category}</h4></a>
                )}
              </div>
              <Link href={post.post_link.cached_url !== undefined ? post.post_link.cached_url : '#'}>
                <a className={classes['h3-container']} href={post.post_link.cached_url} target={post.post_link.linktype === 'url' ? '_blank' : '_self'} rel="noreferrer">
                  {post.is_quote_title ? (
                    <>
                      <h3 className={classnames(classes.quote_title)}>
                        <span dangerouslySetInnerHTML={{ __html: '&OpenCurlyDoubleQuote;' }}></span>
                        {post.post_title}
                        <span dangerouslySetInnerHTML={{ __html: '&CloseCurlyDoubleQuote;' }}></span>
                      </h3>
                    </>
                  ) : (<h3>{post.post_title}</h3>)}
                </a>
              </Link>
            </motion.div>
          </React.Fragment>}
      </Grid>
    </div>
  )
}
