import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import classes from './FreshlyPublished.module.scss'

type FreshlyPublishedProps ={
  right?: boolean
}

export const FreshlyPublished: React.FC<PropsWithChildren<FreshlyPublishedProps>> = () => (
  <div className={ classnames(classes['freshly-published']) }>
    <span>Check it out!</span>
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 144 54.5"
      xmlSpace="preserve"
    >
      <style>{'.st1{fill:#ff6274}'}</style>
      <g id="Group_3157" transform="translate(17031.777 20945.127)">
        <g id="Group_3156" transform="rotate(9 124598.433 -118070.008)">
          <path
            id="Path_5152"
            className="st1"
            d="M49.8 17.8c0 .7-.6 1.2-1.2 1.2h-.3c-3.7-.6-7.5-.9-11-2-.4-.1-.7-.6-.6-1 .1-.4.6-.7 1-.6 3.2.7 6.5.8 9.7 1.1 0-1.2 0-2.4-.1-3.7-.2-1.7-.3-3.3-.2-5 .1-.8 1.3-1.4 1.7-.4 1.4 3.1 1 7.1 1 10.4z"
          />
          <path
            id="Path_5153"
            className="st1"
            d="M.3 30.1c.4-1.5 1-3 1.5-4.5s1.2-2.9 1.8-4.3c1.2-2.8 2.7-5.5 4.4-8 1.6-2.4 3.5-4.6 5.7-6.5 2-1.7 4.4-2.9 7-3.5.2 0 .3-.1.5-.1l.5-.1h1.9c.3 0 .6 0 .9.1 1.3.1 2.5.4 3.7.9l.9.3c.3.1.6.3.9.4l.6.2.4.2c.3.1.6.3.9.4.3.2.6.3.9.5.3.2.6.3.9.5l.4.3c.1.1.3.2.4.3l.9.5.9.6.9.6c.6.4 1.1.8 1.7 1.2l.8.6.4.3.4.3.8.7c.1.1.3.2.4.3l.4.4c2.2 1.8 4.3 3.8 6.6 5.8.1.1.1 0 .2 0v-.2c-1.6-2.7-3.4-5.2-5.4-7.5-1-1.2-2-2.3-3.1-3.3-.3-.3-.6-.5-.8-.8l-.8-.7-.9-.7c-.3-.3-.6-.5-.9-.7l-.9-.7c-.2-.1-.3-.2-.5-.3l-.5-.3c-.6-.4-1.3-.8-1.9-1.1-1.3-.7-2.7-1.2-4.1-1.6-1.4-.4-2.9-.6-4.4-.6h-2.3l-.4.2c-.2 0-.4.1-.5.1l-.5.1c-.2 0-.4.1-.5.1-1.5.4-2.8.9-4.1 1.7-1.3.8-2.5 1.7-3.7 2.7-2.3 2.2-4.2 4.6-5.7 7.3-1.6 2.7-2.9 5.6-4 8.6S.9 26.9.3 30c0 .1 0 .2.1.2-.2.1-.1 0-.1-.1z"
          />
        </g>
      </g>
    </svg>
  </div>

)
