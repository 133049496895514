import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import { isNotNull } from '../../utils/nonNull'
import classes from './alternateBackground.module.scss'

export type AlternateBackgroundProps = PropsWithChildren<{
  variant: 'light' | 'dark' | 'image'
  children: React.ReactNode
  imageSrc?:string
  curveOutline?: boolean
}>

export const AlternateBackground:React.FC<AlternateBackgroundProps> = ({ children, variant, imageSrc, curveOutline= false })=> {
  const bgClasses = [classes.alternateBgWrapper, classes.alternateBg, classes[`alternateBg--${variant}`]]

  if (curveOutline) bgClasses.push(classes['curve-outline'])

  return (
    <>
      {curveOutline && (
        <div className={classnames([classes.curvedTop, classes[`curvedTop--${variant}`]])}></div>
      )}
      <div
        className={classnames(bgClasses)}
        style={{ backgroundImage: (variant=== 'image' && isNotNull(imageSrc))  ? `url("${imageSrc}${IMAGE_FILTER}")` : 'none' }}
      >
        <div className={classes.childContainer}>{children}</div>
      </div>
    </>
  )
}
