/* eslint-disable unicorn/consistent-function-scoping */

import classnames from 'classnames'
import { motion, useCycle } from 'framer-motion'
import React, { PropsWithChildren } from 'react'
import Masonry from 'react-masonry-css'
import { AlternateBackground } from '../../components/AlternateBackground'
import { Button } from '../../components/Button'
import { Grid } from '../../components/Grid'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import classes from './FeaturedPosts.module.scss'
import { FreshlyPublished } from './FreshlyPublishedIcon'
import { MobileSlider } from './MobileSlider'
import { ThePost } from './ThePost'

export type FeaturedPostsProps = {
  posts: Post[]
  is_freshly_published: boolean
  is_load_more: boolean
  alt_bg: boolean
  footer?: boolean
}

export type Post = {
  id: number
  post_category: string
  is_quote_title: boolean
  post_title: string
  image_type: string
  is_downloadable: boolean
  post_link: {
    linktype: string
    cached_url: string
  }
  image: ImageType
  cursor_color: {
    color: string
  }
}

export type PostImage = {
  component: string
  image: {
    filename: string
    alt: string
  }
}

export type ImageType = {
  alt: string
  copyright: string
  filename: string
  id: number
  name: string
  title: string
}

const loadMoreVariants = {
  open: {
    x: 0,
    opacity: 0,
    transition: {
      x: { stiffness: 1000, velocity: 100, from: 100, duration: 0.5 },
      opacity: { duration: 1 },
    },
  },
  closed: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, from: 100, duration: 0.5 },
      opacity: { duration: 1 },
    },
  },
}

const WhiteBGWrapper: React.FC = ({ children }) => (
  <div className={classes.white_bg}>{children}</div>
)

export const FeaturedPosts: React.FC<PropsWithChildren<{ slice: FeaturedPostsProps }>> = ({ slice }) => {
  const [isCollapsed, toggleCollapse] = useCycle(true, false)
  const numberOfPosts = slice.posts.length
  const isMobile = useMediaQuery('(max-width: 1023px)')

  const variants = {
    open: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05, delayChildren: 0.1, staggerDirection: 1,
        opacity: { duration: 0.2 },
      },
    },
    closed: {
      opacity: 0,
      transition: {
        opacity: { duration: 1 },
      },
    },
  }

  const featuredPosts: Post[] = []
  const masonryGridClasses = [classes.masonryGrid]

  slice.posts.map((post: Post, index:number)=> {
    featuredPosts.push({
      id: index,
      post_category: post.post_category,
      is_quote_title: post.is_quote_title,
      post_title: post.post_title,
      image_type: post.image_type,
      is_downloadable: post.is_downloadable,
      post_link: {
        linktype: post.post_link.linktype,
        cached_url: post.post_link.cached_url,
      },
      image: post.image,
      cursor_color: {
        color: post?.cursor_color?.color,
      },
    })
  })

  const inner =  (
    <Grid container className={classes['featured-posts']}>
      {!isMobile ? (
        <>
          <motion.div
            className={classes.motion}
          >
            {slice.is_freshly_published && <FreshlyPublished />}
            <Masonry
              breakpointCols={{
                default: 2,
              }}
              columnClassName={classes.masonryColumn}
              className={classnames(masonryGridClasses)}
            >
              {featuredPosts.map((Post, i: number) => {
                const topPostClasses = i < 1 ? [classes.left] : [classes.right]

                return (
                  <motion.div
                    animate={'open'}
                    variants={variants}
                    key={i}
                  >
                    {i <= 1 ?
                      <ThePost
                        classname={classnames(topPostClasses)}
                        keyProp={i}
                        post={Post}
                        numberOfPosts={numberOfPosts} />
                      : null}
                  </motion.div>
                )})}
              {slice.posts.map((Post, i: number) => {
                const hiddenPostClasses =  i % 2 === 0 ? [classes.left] : [classes.right]

                return i <= 1 ? null : (
                  <motion.div
                    animate={isCollapsed && slice.is_load_more ? 'closed' : 'open'}
                    variants={variants}
                    key={i}
                  >
                    <ThePost
                      keyProp={i}
                      key={i}
                      classname={classnames(hiddenPostClasses)}
                      numberOfPosts={numberOfPosts}
                      post={Post} />
                  </motion.div>
                )})}
            </Masonry>
            {isCollapsed && slice.is_load_more && slice.posts.length > 2 &&
              <motion.div
                initial={'open'}
                animate={'closed'}
                variants={loadMoreVariants}>
                <Grid container className={classes['featured-posts__load-more']}>
                  <Button
                    icon={isCollapsed ? 'plus' : 'minus'}
                    variant={'primary'}
                    // @ts-ignore
                    onClick={toggleCollapse}
                  >
                    {isCollapsed ? 'Load more' : 'Load less'}
                  </Button>
                </Grid>
              </motion.div>
            }
          </motion.div>
        </>)
        :
        (<div className={classes['featured-posts__slider']}>
          <div className={classes['featured_posts__slider-row']}>
            {slice.is_freshly_published && <FreshlyPublished />}
            <MobileSlider posts={slice.posts} numberOfPosts={numberOfPosts} />
          </div>
        </div>)}
    </Grid>
  )

  return slice.alt_bg ?
    ( <AlternateBackground variant={'light'} curveOutline={true}>{inner}</AlternateBackground>)
    : (!slice.footer ? (<WhiteBGWrapper>{inner}</WhiteBGWrapper>) : <>{inner}</>)
}

export default FeaturedPosts
