import React, { PropsWithChildren } from 'react'

type DownloadProps = {
  className: string
}

export const DownloadIcon:React.FC<PropsWithChildren<DownloadProps>> = ({ className }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 23.6 23.5"
    xmlSpace="preserve"
    className={className}
  >
    <style>{'.st0{fill:#4a4a4a}'}</style>
    <g id="Group_3050" transform="translate(-890.249 -458.006)">
      <g id="Group_2976" transform="rotate(180 466.745 250.543)">
        <path
          id="Path_5137_-_Outline"
          className="st0"
          d="M31.6 19.6c1.6 0 3.1.3 4.6.8 1.1.4 2.1 1 3 1.8.8.8 1.6 1.7 2.1 2.7 1.1 2 1.8 4.3 1.9 6.6.1 3.2-1.1 6.3-3.2 8.6-.7.7-1.5 1.4-2.4 1.9-5.2 2.1-11.1.8-15-3.2-.5-.6-.9-1.2-1.3-1.9-1-1.8-1.6-3.9-1.7-6 0-4.1 2.2-7.9 5.8-9.9 1.9-.9 4-1.4 6.2-1.4zm4.3 1.5c-3.3-1.2-6.9-1-10.1.6-3.3 1.9-5.4 5.4-5.3 9.2.1 2 .6 3.9 1.6 5.6l1.2 1.8c3.6 3.8 9.2 5 14 3 .8-.5 1.5-1.1 2.2-1.7 2-2.2 3-5.1 3-8.1-.2-2.2-.8-4.3-1.8-6.3-1.1-1.8-2.8-3.3-4.8-4.1z"
        />
      </g>
      <g id="Group_2977" transform="rotate(90 4325.446 3499.692)">
        <g id="Group_1870" transform="rotate(45 -7699.705 5024.436)">
          <path
            id="Path_5147"
            className="st0"
            d="M.4 10.6c3.5-3.7.1-.3 1.5-1.7.7-.7 1-.9 1.6-1.6.7-.7.7-.5 1.4-1.2.1-.1.1-.2 0-.3-.1-.1-.1-.1-.2 0-.9.5-.9.5-1.7 1-.5.4-1 .7-1.4 1.2C.1 9.3.9 8.5 0 10.3"
          />
          <path
            id="Path_5147_-_Outline"
            className="st0"
            d="m.5 10.7-.4-.3-.4-.2c.3-.4.4-.7.6-1.1.1-.4.1-.5.7-1 .1-.1.3-.2.5-.3l.4-.4c.3-.3.6-.5 1-.8.7-.5.8-.5 1.4-.9l.3-.2c.1 0 .4 0 .5.2.1.2.1.4 0 .6-.3.2-.5.4-.8.6-.2.2-.5.4-.7.6-.2.3-.5.5-.7.7l-.6.6c-.1.1-.1.2-.2.2 0 .1-.1.2-.2.3L.5 10.7zm3.7-4.4c-.3.2-.5.3-1.1.7-.3.2-.6.5-.9.7-.1.1-.2.3-.4.4l-.4.4c-.6.5-.6.5-.7.8-.1.4-.3.7-.5 1 .4-.5.7-.8 1-1 0-.1 0-.2.1-.2l.1-.1.3-.3c.4-.4.7-.7 1-.9.1-.2.3-.4.6-.7.2-.2.5-.4.7-.6.1-.1.2-.1.2-.2z"
          />
          <path
            id="Path_6301"
            className="st0"
            d="M10.6 5.8c-3.8-3.5-1.5-1.3-3-2.6-.7-.7-.9-1-1.6-1.6C5.3.9 5.5.8 4.8.2c-.1-.1-.2-.1-.3 0 0 .1-.1.1 0 .2l1 1.6c.3.5.7 1 1.2 1.4.9 1.2 2.2 2.2 3.6 2.8"
          />
          <path
            id="Path_6301_-_Outline"
            className="st0"
            d="m10.7 5.6-.3.4-.2.4c-1.5-.6-2.7-1.6-3.7-2.8-.2-.2-.3-.4-.4-.5-.3-.3-.5-.6-.8-1-.4-.6-.5-.7-.8-1.3L4.3.5c-.1-.2-.1-.4.1-.5.2-.1.4-.1.6 0l.7.7c.1.3.3.5.5.7l.7.7.9.9.5.5h.1c.1 0 .1.1.3.2.3.4 1 1 2 1.9zM5 .8c.2.4.3.5.7 1.1.3.3.5.6.8.9l.4.4c.9 1.2 2.1 2.1 3.4 2.7-1.7-1.6-2.1-1.9-2.2-2L8 3.8c-.1-.1-.3-.2-.5-.4l-1-1c-.2-.2-.4-.4-.6-.7-.3-.2-.5-.4-.7-.7 0-.1-.1-.1-.2-.2z"
          />
          <path
            id="Path_5148"
            className="st0"
            d="M5.6 5.4c0-.3-.2-.5-.5-.5H5c-1.5.3-2.6 0-4 .4-.2 0-.3.2-.2.4 0 .2.2.3.4.3 1.3-.3 2.1.1 3.4 0v1.4c-.1.6-.1 1.3-.1 1.9 0 .3.5.5.7.2.4-1.3.5-2.7.4-4.1z"
          />
          <path
            id="Path_5148_-_Outline"
            className="st0"
            d="M5.1 4.7c.4 0 .7.3.7.7v.8c.1 1.2 0 2.3-.4 3.5-.1.1-.3.3-.5.3-.3 0-.6-.3-.7-.6V8.1c0-.2 0-.4.1-.7V6.2H2.1c-.3 0-.6 0-.9.1-.2 0-.6-.2-.6-.5-.1-.3.1-.6.4-.7.8-.2 1.6-.3 2.3-.3.5 0 1.1 0 1.6-.1h.2zm.2.7c0-.1-.1-.2-.2-.2H5c-.6.1-1.1.1-1.7.1-.7 0-1.5.1-2.2.3 0 0-.1 0-.1.1s0 .1.1.1c.3-.1.7-.1 1-.1H4.8v1.8c0 .2 0 .5-.1.7-.1.4-.1.8 0 1.2 0 .1.1.1.2.1.4-1 .5-2.2.4-3.2v-.9z"
          />
        </g>
      </g>
    </g>
  </svg>
)